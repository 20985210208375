import { type UserReviews_userProfile$data } from '@/__generated__/relay/UserReviews_userProfile.graphql.js';
import { PLACEHOLDER_AVATAR_IMAGE } from '@/components/Avatar/constants.js';

type RecommendationNode =
  UserReviews_userProfile$data['userReviews']['edges'][0];

export const userReviewMock = {
  node: {
    ' $fragmentSpreads': {} as RecommendationNode['node'][' $fragmentSpreads'],
    __fragmentOwner: {},
    __fragments: {},
    associatedWork: {
      paidProjectProfile: {
        client: {
          id: 'WyJVc2VyUHJvZmlsZSIsODY1MzY4XQ==',
        },
        id: 'WyJQYWlkUHJvamVjdCIsNTgxNjg4XQ==',
      },
      portfolioProject: null,
      productizedService: null,
    },
    author: {
      analyticsId: 0,
      avatarImage: PLACEHOLDER_AVATAR_IMAGE,
      displayUsername: '',
      firstName: 'Name',
      id: 'WyJVc2VyUHJvZmlsZSIsODY1MzY4XQ==',
      lastName: '',
      onboardingType: 'CLIENT',
      organization: {
        logoImage: null,
        name: 'Your Dream Client',
      },
    },
    commentOnlyVisibleToRecipient: false,
    createdAt: '2022-08-22T15:40:22.763Z',
    id: 'WyJUZXN0aW1vbmlhbCIsMjQzNTBd',
    isPositiveReview: true,
    onlyVisibleToRecipient: false,
    reviewText:
      'Recommendations you receive after completing a paid project will appear here...',
  },
  // this assertion is to get rid of the TS warnings about fragments keys in the object not present/matching
} as RecommendationNode;
