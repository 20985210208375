import { type UserReviews_userProfile$data } from '@/__generated__/relay/UserReviews_userProfile.graphql.js';
import { type RecommendationNode } from '@/components/Recommendation/types.js';
import { dayjs } from '@contra/date';

export const buildUserReviews = (
  userReviews: UserReviews_userProfile$data['userReviews'],
  userProfileOwnerId?: string | null,
): RecommendationNode[] => {
  const recommendations =
    userReviews?.edges?.map(({ node }) => ({
      ...node,
    })) ?? [];

  if (recommendations.length === 0) return [];

  const filteredByVisible = recommendations.filter(
    ({
      commentOnlyVisibleToRecipient,
      isPositiveReview,
      onlyVisibleToRecipient,
    }) =>
      isPositiveReview &&
      !commentOnlyVisibleToRecipient &&
      !onlyVisibleToRecipient,
  );

  const cleanedNodes = filteredByVisible.map((node) => {
    const dateFormatter = dayjs(node.createdAt);
    // If the review is associated with a paid project, we only show if the author is the client
    // Otherwise, we show the review
    const resolvedClientId = node.associatedWork.paidProjectProfile
      ? node.associatedWork.paidProjectProfile.client.id === userProfileOwnerId
        ? null
        : node.author?.id
      : node.author?.id;
    const resolvedRole: RecommendationNode['role'] =
      node.author.id === resolvedClientId ? 'Client' : 'Independent';
    const resolvedCompanyName = node.author.organization?.name ?? null;

    return {
      author: node.author,
      company: {
        avatarImage: node.author.organization?.logoImage ?? null,
        companyName: resolvedCompanyName,
      },
      createdAt: dateFormatter.format('MMM D, YYYY'),
      id: node.id,
      review: node.reviewText ? node.reviewText : '',
      role: resolvedRole,
    };
  });

  return cleanedNodes;
};
