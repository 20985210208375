import { type Cover } from './projects.js';
import { type Services_userProfile$data } from '@/__generated__/relay/Services_userProfile.graphql.js';

export const serviceMock: Services_userProfile$data['productizedServices']['edges'][0] & {
  node: {
    isMock?: boolean;
  };
} = {
  node: {
    analyticsId: 0,
    duration: {
      amount: 3,
      interval: 'WEEK',
    },
    id: 'preview-service-mock-uuid',
    isMock: true,
    portfolioProjects: {
      edges: [
        {
          node: {
            analyticsId: 0,
            cover: {
              __typename: 'PortfolioProjectImageCover',
              image: {
                animated: false,
                height: 0,
                id: 'preview-project-mock-image-id',
                originalFormat: 'jpg',
                sizeBytes: 0,
                uid: 'o2oakujpn0g5bhtfjz46',
                width: 0,
              },
            } as Cover,
            id: 'WyJQb3J0Zm9saW9Qcm9qZWN0IiwxNDQwMTc0XQ==',
            slug: 'preview-project-mock-uuid-example-project',
            title: 'Example Linked Project',
          },
        },
      ],
    },
    price: {
      amount: 0,
      interval: null,
      timeline: '15 Minutes',
      type: 'FIXED_PRICE',
    },
    productizedServiceType: 'BUY_NOW_REQUEST',
    slug: '',
    title: 'Example Service',
  },
};
