import { useCurrentUser } from './useCurrentUser.js';
import { type SupportedFragmentNames } from './useIPFragment.js';
import { useSearchParameter } from './useSearchParameter.js';
import { useTheme } from './useTheme.js';
import { useUserProfile } from './useUserProfile.js';
import { type Projects_userProfile$data } from '@/__generated__/relay/Projects_userProfile.graphql.js';
import { type Services_userProfile$data } from '@/__generated__/relay/Services_userProfile.graphql.js';
import { type UserProfile_userProfile$data } from '@/__generated__/relay/UserProfile_userProfile.graphql.js';
import { type UserReviews_userProfile$data } from '@/__generated__/relay/UserReviews_userProfile.graphql.js';
import { type Cover, projectMock } from '@/__mocks__/preview/projects.js';
import { serviceMock } from '@/__mocks__/preview/services.js';
import { userProfileMock } from '@/__mocks__/preview/userProfile.js';
import { userReviewMock } from '@/__mocks__/preview/userReviews.js';
import { TemplateAndThemeContext } from '@/contexts/TemplateAndThemeContext/TemplateAndThemeContext.js';
import { type TemplateAndThemeContextType } from '@/contexts/TemplateAndThemeContext/types.js';
import { type UserProfile } from '@/contexts/UserProfileContext.js';
import { PROJECT_COVERS_UIDS } from '@/templates/globalPreviewConfig.js';
import { type ThemeNames } from '@/templates/templateTypes.js';
import { PreviewConfig } from '@/templates/types.js';
import { buildUserReviews } from '@/utilities/buildUserReviews.js';
import { getVisibleServices } from '@/utilities/service/getVisibleServices.js';
import { useCallback, useContext } from 'react';
import {
  type KeyType,
  type KeyTypeData,
} from 'react-relay/relay-hooks/helpers';

const MINIMUM_QUANTITY_PROJECTS = 1;
const MINIMUM_QUANTITY_SERVICES = 1;
const MINIMUM_QUANTITY_RECOMMENDATIONS = 1;

const maybeMockProfile = (
  profile: UserProfile | null,
  isMockPreviewingEnabled: boolean,
): UserProfile | null => {
  if (!isMockPreviewingEnabled || !profile) return profile;

  return {
    ...profile,
    avatarImage: profile?.avatarImage ?? userProfileMock.avatarImage,
    bio: profile?.bio?.length ? profile.bio : userProfileMock.bio,
    externalLinks: profile?.externalLinks?.edges.length
      ? profile.externalLinks
      : userProfileMock.externalLinks,
    firstName: profile?.firstName?.length
      ? profile.firstName
      : userProfileMock.firstName,
    lastName: profile?.lastName?.length
      ? profile.lastName
      : userProfileMock.lastName,
    location: profile?.location?.length
      ? profile.location
      : userProfileMock.location,
    roles: profile?.roles?.edges.length ? profile.roles : userProfileMock.roles,
    title: profile?.title?.length ? profile.title : userProfileMock.title,
  };
};

const mockUserProjects = (
  projects: Projects_userProfile$data,
  config: PreviewConfig,
  currentTheme: ThemeNames,
): Projects_userProfile$data => {
  const userMeetsQuantity =
    projects.portfolioProjects.edges?.length >= MINIMUM_QUANTITY_PROJECTS;

  if (userMeetsQuantity) return projects;

  const quantityToMock =
    config.projects.quantity - projects.portfolioProjects.edges.length;

  const preferredSetForTheme = config.images.set[currentTheme];

  const projectCoversToUse = PROJECT_COVERS_UIDS[preferredSetForTheme].slice(
    0,
    quantityToMock,
  );

  // eslint-disable-next-line unicorn/no-array-reduce
  const mockedProjects = projectCoversToUse.reduce<Array<typeof projectMock>>(
    (accumulator, current, index) => [
      ...accumulator,
      {
        node: {
          ...projectMock.node,
          cover: {
            ...projectMock.node.cover,
            image: {
              ...(projectMock.node.cover as Cover).image,
              uid: current,
            },
          },
          id: `${projectMock.node.id}-${index}`,
        },
      },
    ],
    [],
  );

  return {
    ...projects,
    portfolioProjects: {
      ...projects.portfolioProjects,
      edges: [...projects.portfolioProjects.edges, ...mockedProjects],
    },
  };
};

const mockUserServices = (
  services: Services_userProfile$data,
  config: PreviewConfig,
): Services_userProfile$data => {
  const visibleServices = getVisibleServices(services.productizedServices);

  const userMeetsQuantity =
    visibleServices?.length >= MINIMUM_QUANTITY_SERVICES;

  if (userMeetsQuantity) return services;

  const quantityToMock = config.services.quantity - visibleServices.length;

  const mockedServices = Array.from({ length: quantityToMock }).map(
    (_, index) => ({
      ...serviceMock,
      node: {
        ...serviceMock.node,
        id: `${serviceMock.node.id}-${index}`,
      },
    }),
  );

  return {
    ...services,
    productizedServices: {
      ...services.productizedServices,
      edges: [...services.productizedServices.edges, ...mockedServices],
    },
  };
};

const mockUserRecommendations = (
  reviews: UserReviews_userProfile$data,
): UserReviews_userProfile$data => {
  const visibleRecommendations = buildUserReviews(reviews.userReviews);

  const userMeetsQuantity =
    visibleRecommendations.length >= MINIMUM_QUANTITY_RECOMMENDATIONS;

  if (userMeetsQuantity) return reviews;

  return {
    ...reviews,
    userReviews: {
      ...reviews.userReviews,
      edges: [userReviewMock],
    },
  };
};

const mockUserProfile = (
  profile: UserProfile_userProfile$data,
): UserProfile_userProfile$data => {
  return {
    ...profile,
    avatarImage: profile?.avatarImage ?? userProfileMock.avatarImage,
    bio: profile?.bio?.length ? profile.bio : userProfileMock.bio,
    externalLinks: profile?.externalLinks?.edges.length
      ? profile.externalLinks
      : userProfileMock.externalLinks,
    firstName: profile?.firstName?.length
      ? profile.firstName
      : userProfileMock.firstName,
    lastName: profile?.lastName?.length
      ? profile.lastName
      : userProfileMock.lastName,
    location: profile?.location?.length
      ? profile.location
      : userProfileMock.location,
    roles: profile?.roles?.edges.length ? profile.roles : userProfileMock.roles,
    title: profile?.title?.length ? profile.title : userProfileMock.title,
  };
};

const getMockDataByFragmentName = <TKey extends KeyType>({
  config,
  currentTheme,
  fragmentName,
  originalData,
}: {
  config: PreviewConfig;
  currentTheme: ThemeNames;
  fragmentName: SupportedFragmentNames;
  originalData: KeyTypeData<TKey>;
}): KeyTypeData<TKey> => {
  if (!originalData) return {};

  switch (fragmentName) {
    case 'Projects_userProfile':
      return mockUserProjects(
        originalData as Projects_userProfile$data,
        config,
        currentTheme,
      );
    case 'UserReviews_userProfile':
      return mockUserRecommendations(
        originalData as UserReviews_userProfile$data,
      );
    case 'Services_userProfile':
      return mockUserServices(
        originalData as Services_userProfile$data,
        config,
      );
    case 'UserProfile_userProfile':
      return mockUserProfile(originalData as UserProfile_userProfile$data);
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unsupported fragment ${fragmentName} - cannot mock`);
      return {};
  }
};

/**
 * This is only with regards to the profile being mockable or not and _not_ related to onboarding progress, etc.
 */
const getIsProfileMockable = (userProfile: UserProfile): boolean => {
  if (!userProfile) return false;

  const visibleServices =
    userProfile.productizedServices?.edges.map(({ node }) => node) ?? [];

  const visibleRecommendations = buildUserReviews(userProfile.userReviews);

  const isProfileMockable =
    !userProfile?.portfolioProjects?.edges?.length ||
    !visibleServices.length ||
    !visibleRecommendations?.length ||
    !userProfile?.bio?.length;

  return isProfileMockable;
};

type MaybeMockData = <TKey extends KeyType>(
  originalData: KeyTypeData<TKey>,
  fragmentName: SupportedFragmentNames,
) => KeyTypeData<TKey>;

export const useMockPreview = <TKey extends KeyType>() => {
  const hideTools = useSearchParameter('hideTools', 'boolean');
  const isPreviewingOverride = useSearchParameter('isMockPreview', 'boolean');
  const currentUser = useCurrentUser();
  const userProfile = useUserProfile();
  const { current } = useTheme();
  const context = useContext<TemplateAndThemeContextType>(
    TemplateAndThemeContext,
  );

  const maybeMockData = useCallback<MaybeMockData>(
    (originalData, fragmentName) => {
      return getMockDataByFragmentName<TKey>({
        config: context.template.previewConfig,
        currentTheme: current,
        fragmentName,
        originalData,
      });
    },
    [context.template.previewConfig, current],
  );

  /**
   * Preview mode allowed to be enabled under the following conditions:
   * 1. User is looking at their own profile
   * 2. User has not clicked the Preview button (i.e, hideTools search param is true)
   * 3. User is not looking at a template they already have launched (i.e, we don't want to inject mock content into their "launched" template even if they are using the templates drawer, picking themes, etc)
   *
   * NOTE: This only _allows_ preview mode - whether or not mock data is returned
   * depends on the `useIPFragment` hook and the configuration.
   */
  const isOwnProfile =
    currentUser && userProfile
      ? currentUser?.userAccount?.profile?.id === userProfile?.id
      : false;

  const isProfileMockable = userProfile
    ? getIsProfileMockable(userProfile) && isOwnProfile
    : false;

  const isPreviewingNewTemplate =
    userProfile?.independentPortfolioTemplateTheme?.template
      ?.developmentSlug !== context.template.current;

  const isMockPreviewingEnabled =
    isOwnProfile && isPreviewingNewTemplate && !hideTools;

  const shouldShowPreviewModal = hideTools && isOwnProfile && isProfileMockable;

  return {
    ...context.template.previewConfig,
    isContextLoaded: context.isContextLoaded,
    isMockNode: (node?: object) => Boolean(node && 'isMock' in node),
    isMockPreviewingEnabled: isPreviewingOverride || isMockPreviewingEnabled,
    isPreviewingNewTemplate,

    isProfileMockable,

    /**
     * Only mocks the additional data the user doesn't have if we're in preview mode, otherwise returns the original data
     */
    maybeMockData,

    /**
     * Only mocks the additional data the user doesn't have if we're in preview mode, otherwise returns the original data.
     */
    maybeMockProfile: (profile: UserProfile | null) =>
      // This one is still here because we might need to use it to mock data coming from the deprecated `useUserProfile` hook
      maybeMockProfile(profile, isMockPreviewingEnabled),
    shouldShowPreviewModal,
  };
};
