// TODO was added as part of NodeNext migration; remove
/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
import { TemplateAndThemeContext } from '@/contexts/TemplateAndThemeContext/TemplateAndThemeContext.js';
import { type TemplateAndThemeContextType } from '@/contexts/TemplateAndThemeContext/types.js';
import { useContext } from 'react';

/**
 * To get a fully-typed theme object, use a TemplateThemeType union that combines
 * a typeof for all your themes. See @/templates/barcelona/ for an example!
 *
 * const { themeObject } = useTheme<typeof dawn | typeof morning>();
 */
export const useTheme = <
  CurrentThemeTokens,
>(): TemplateAndThemeContextType<CurrentThemeTokens>['theme'] & {
  isContextLoaded: boolean;
} => {
  const context = useContext<TemplateAndThemeContextType<CurrentThemeTokens>>(
    // @ts-ignore
    TemplateAndThemeContext,
  );
  return { ...context.theme, isContextLoaded: context.isContextLoaded };
};
