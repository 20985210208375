import { type SocialMediaSiteNid } from '@/__generated__/api.js';

export const userProfileMock = {
  avatarImage: {
    animated: false,
    // eslint-disable-next-line @contra/enforce-stitches-tokens
    height: 612,
    id: 'WyJDb250cmFJbWFnZSIsMzM1ODg4NDJd',
    uid: 'h5n42getg1wnjro4pozf',
    url: 'https://media.contra.com/image/upload',
    // eslint-disable-next-line @contra/enforce-stitches-tokens
    width: 612,
  },
  bio: 'Your bio goes here to introduce yourself to the world...',
  externalLinks: {
    edges: [
      {
        node: {
          id: 'WyJVc2VyUHJvZmlsZUV4dGVybmFsTGluayIsOTIwMTk0XQ==',
          label: 'TikTok',
          socialMediaSite: {
            id: 'WyJTb2NpYWxNZWRpYVNpdG2iLDI2XQ==',
            nid: 'TIKTOK' as SocialMediaSiteNid,
          },
          url: 'https://www.tiktok.com/@contrahq',
        },
      },
      {
        node: {
          id: 'WyJVc2VyUHJvZmlsZUV4dGVybmFsTGluayIsOTIwMTk1XQ==',
          label: 'Twitter',
          socialMediaSite: {
            id: 'WyJTb2NpYWxNZWRpYVNpdGZiLDI2XQ==',
            nid: 'TWITTER' as SocialMediaSiteNid,
          },
          url: 'https://twitter.com/contra',
        },
      },
      {
        node: {
          id: 'WyJVc2VyUHJvZmlsZUV4dGVybmFsTGluayIsMzkxOTYyM10=',
          label: 'Instagram',
          socialMediaSite: {
            id: 'WyJTb2NpYWxNZWRpYVNpdDiLDEyXQ==',
            nid: 'INSTAGRAM' as SocialMediaSiteNid,
          },
          url: 'https://instagram.com/contra',
        },
      },
    ],
  },
  firstName: 'Jenny',
  generalInquiryCtaIsVisible: true,
  id: 'preview-profile-mock-uuid',
  lastName: 'Lee',
  location: 'Los Angeles, CA',
  portfolioProjects: {
    edges: [
      {
        node: {
          id: 'preview-project-mock-uuid',
          roles: {
            edges: [
              {
                node: {
                  id: 'WyJSb2xlIiw2ODI0NF0=',
                  name: 'Frontend Engineer',
                },
              },
            ],
          },
          slug: 'preview-project-mock-uuid-example-project',
          title: 'Example Project',
        },
      },
    ],
  },
  productizedServices: {
    edges: [
      {
        node: {
          id: 'preview-service-mock-uuid',
          portfolioProjects: {
            edges: [
              {
                node: {
                  id: 'WyJQb3J0Zm9saW9Qcm9qZWN0IiwxNDQwMTc0XQ==',
                  slug: 'preview-project-mock-uuid-example-project',
                },
              },
            ],
          },
          price: {
            amount: 0,
            interval: null,
            timeline: '15 Minutes',
            type: 'FIXED_PRICE',
          },
          slug: 'preview-service-mock-uuid-example-service',
          title: 'Example Service',
        },
      },
    ],
  },
  roles: {
    edges: [
      {
        node: {
          id: 'WyJSb2xlIiw2ODIyMV0=',
          name: 'Web Designer',
        },
      },
      {
        node: {
          id: 'WyJSb2xlIiw2ODM3MF0=',
          name: 'Frontend Engineer',
        },
      },
      {
        node: {
          id: 'WyJSb2xlIiw2ODM1MF0=',
          name: 'Content Creator',
        },
      },
    ],
  },
  title: 'Your professional one-liner will go here...',
  userReviews: {
    edges: [
      {
        node: {
          author: {
            displayUsername: '',
            firstName: 'Client',
            id: 'preview-user-review-author-mock-uuid',
            lastName: 'Name',
            organization: {
              name: 'Your Dream Client Company',
            },
          },
          id: 'preview-user-review-mock-uuid',
          reviewText:
            'Recommendations you receive after completing a paid project will appear here...',
        },
      },
    ],
  },
  visitorCanEdit: true,
};
