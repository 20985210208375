import { type Projects_userProfile$data } from '@/__generated__/relay/Projects_userProfile.graphql.js';

export type Cover = {
  __typename: 'PortfolioProjectImageCover';
  image: {
    animated: boolean;
    height: number;
    id: string;
    originalFormat: string;
    sizeBytes: number;
    uid: string;
    width: number;
  };
};

type Project = Projects_userProfile$data['portfolioProjects']['edges'][0];

export const projectMock = {
  node: {
    ' $fragmentSpreads': {} as Project['node'][' $fragmentSpreads'],
    analyticsId: 0,
    cover: {
      __typename: 'PortfolioProjectImageCover',
      image: {
        animated: false,
        height: 0,
        id: 'preview-project-mock-image-id',
        originalFormat: 'jpg',
        sizeBytes: 0,
        uid: 'o2oakujpn0g5bhtfjz46',
        width: 0,
      },
    } as Cover,
    id: 'preview-project-mock-uuid',
    isMock: true,
    organizations: {
      edges: [],
    },
    roles: {
      edges: [
        {
          node: {
            id: 'preview-project-mock-role-id',
            name: 'Your roles are listed here',
          },
        },
      ],
    },
    slug: '',
    title: 'Example Project',
    tools: {
      edges: [
        {
          node: {
            id: 'preview-project-mock-tool-id',
            name: 'Your tools are listed here',
          },
        },
      ],
    },
  },
} as Project;
